body {
  margin: 0;
  font-family: "Comic Sans MS", "Comic Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Comic Sans MS", "Comic Sans";
}
