.MainStopGameBG {
    position: fixed;
    text-align: center;
    margin: auto;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    color: white;
  }

.StopGameTitle {
    position: relative;
      top: 80px;
      margin: auto;
      width: 700px;
      height: 80px;
  }

  .StopGameTitle .VerticalAlignDiv {
    border:  0;
    font-weight: bold;
    font-size: 18pt;
  }

  .StopGameSubTitle {
    position: relative;
      top: 120px;
      margin: auto;
      width: 500px;
      height: 80px;
  }

  .StopGameSubTitle .VerticalAlignDiv {
    border:  0;
  }


  .StopGameButtons{
    position: relative;
    top: 100px;
  }
  .StopGameButtons .ImageButton {
    position: relative;
    top: 100px;
    width: 200px;
    height: 100px;
    margin-left: 70px;
    margin-right: 70px;
    border: none;
    cursor: pointer;
}