.QuestionBox {
    position: absolute;
    bottom: 163px;
    width: calc(100% - 80px);
    height: auto;
    margin: 0px 40px;
}

.QuestionBox1{
    position: absolute;
    bottom: 120px;
    width: calc(100% - 80px);
    height: auto;
    margin: 0px 40px;
}

.QuestionInnerBox {
    position: relative;
    width: 700px;
    height: auto;
    margin: auto;
    display: flex;
}

.QuestionSubmit {
    width: 60px;
    height: 60px;
    margin: 0 20px;
    margin-top: 20px;  /* 3 or 4 questions */
    border-radius: 30px;
    background: none;
    border: 1px solid black;
    cursor: pointer;
}

.QuestionSubmit1 {
    width: 60px;
    height: 60px;
    margin-top: 240px;
    margin-left: 300px;
    border-radius: 30px;
    background: none;
    border: 1px solid black;
    cursor: pointer;
}

.QuestionSubmitImage1 {
    width: 205px;
    height: 100px;
    margin-top: 240px;
    margin-left: 300px;
    border-radius: 30px;
    background: none;
    border: 1px solid black;
    cursor: pointer;
}

.QuestionDelay {
    margin-top: 250px;
    margin-left:10px;
    width:220px;
    height: 60px;
    background: none; 
}

.QuestionDelay .VerticalAlignDiv {
    border: none;
}

.QuestionSubmit3 {
    width: 60px;
    height: 60px;
    margin: 0 20px;
    margin-top: 60px;  /* 3 or 4 questions */
    border-radius: 30px;
    background: none;
    border: 1px solid black;
    cursor: pointer;
}

.Question {
    width: 300px;
    height: 150px;
    background: none;
}

.QuestionContainer {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-top:10px;
}

.QuestionContainer button {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
}

.QuestionContainer .ImageTextButton {
    top: -107%;
    height:  100%;
    font-size: clamp(1rem, -0.875rem + 1.5vw, 4.5rem);
    padding: 6px;
}