.MainBG {
    position: relative;
    text-align: center;
    margin: auto;
    height: 100%;
    width: 100%;
  }

  .Title {
    position: relative;
      top: 100px;
      margin: auto;
      width: 300px;
      height: 80px;
  }

  .Left {
    position: absolute;
    left:150px;
    top:300px;
    text-align: left;
  }

  .Left p {
    width: 300px;
    text-align: justify;
    border: 1px solid rgb(213, 213, 213);
    padding: 10px;
  }

  .Left p span {
      display: block;
  }

  .Right {
    position: absolute;
    right:150px;
    top:300px;
    text-align: left;
  }

  .Right p {
    width: 300px;
    text-align: justify;
    border: 1px solid rgb(213, 213, 213);
    padding: 10px;
  }

  .Right p span {
    display: block;
}


.Start {
    position: absolute;
    bottom: 100px;
    left: 45%;
    width: 166px;
    height: 81px;
}

.Start button {
  cursor: pointer;
}