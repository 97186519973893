.VerticalAlignDiv {
  width: 100%;
  height: 100%;
  display: table;
  border: 1px solid rgb(213, 213, 213);
}

.VerticalAlignDiv p {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  font-family: "Comic Sans MS", "Comic Sans"
}