.VerticalBox {
    position: absolute;
    bottom: 360px;
    width: calc(100% - 80px);
    height: auto;
    margin: 0px 40px;
    animation-duration: 1s;
}

@keyframes move {
    0%   {bottom: 600px;}
    25%  {bottom: 560;}
    50%  {bottom: 500px;}
    100% {bottom: 400px;}
  }

.VerticalTip {
    position: relative;
    width: 400px;
    height: 100px;
    margin: auto;
    padding: 10px;
}