.LezantaOuterBox {
    position: absolute;
    top: 0px;
    width: 100%;
    height: auto;
    animation-duration: 1s;
}

.LezantaInnerBox {
    position: relative;
    margin: auto;
    width: 700px;
    height: 175px;
}

.LezantaInnerBox button {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    cursor: default;
}

.LezantaInnerBox .ImageTextButton {
    top: -107%;
    height:  100%;
    font-size: clamp(1rem, -0.875rem + 1.8vw, 4.5rem);
    padding: 6px;
}