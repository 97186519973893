.CharacterNameTitle {
    position: relative;
    width: 500px;
    left:270px;
    height: auto;
  }

  .CharacterNameTitle .VerticalAlignDiv {
    padding: 15px 5px;
  }

  .CharacterPhotoContainer {
    position: relative;
    margin: auto;
    width: 300px;
    height: auto;
  }

  .CharacterEnterNameContainer {
    position: relative;
    left: 500px;
    width: 400px;
    height: auto;
  }

  .CharacterEnterNameContainer input {
    width: 100%;
    height: 30px;
    padding: 10px;
    size: 40pt;
    font-weight: bold;
    background-color: rgb(116, 146, 196);
    color: white;
  }

  .PlayGameDisabled {
    position: relative;
    margin: auto;
    width: 166px;
    height: 81px;
  }

  .PlayGame {
    position: relative;
    margin: auto;
    width: 166px;
    height: 81px;
  }

  .PlayGame button {
    cursor: pointer;
  }