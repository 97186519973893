.BottomBarContainer {
    position: absolute;
    bottom: 37px;
    width: calc(100% - 80px);
    height: 80px;
    margin: 0px 40px;
  }

  .BottomBar {
    position: relative;
    top: 15px;
    height: 50px;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
  }

  .BottomBarCharacterName {
    position: relative;
    top: 13px;
    width: 180px;
    color: yellow;
    font-weight: bold;
  }

  .BottomBarImage {
    position: relative;
    top: -25px;
    width: 90px;
    height: 90px;
    /*border-radius: 45px;
    border: 1px solid yellow;
    color: yellow;
    background-color: rgba(0, 0, 0, 0.9);*/
  }

  .BottomBarReponse {
    position: relative;
    top: 15px;
    width: calc(100% - 300px);
    color: yellow;
    text-align: left;
    margin-left: 20px;
    animation: linearwipe 1s steps(60, end); 
    white-space: nowrap;
    overflow: hidden; 
  }

  @keyframes linearwipe{ 
    from { width: 0; } 
    to {width: calc(100% - 300px);}
  }  
