 .CharacterTitle {
    position: relative;
    margin: auto;
    width: 1000px;
    height: auto;
  }

  .CharacterTitle .VerticalAlignDiv {
    padding: 15px 5px;
  }

  .animate {
    opacity: 0;
    transition: all 250ms linear 100ms; 
  }

  .CharacterHeroesContainer {
    position: relative;
    margin: auto;
    width: 1000px;
    height: auto;
  }
  .CharacterHeroes {
    display: flex;
    margin: auto;
    padding-left: 50px;
  }

  .PlayDisabled {
    position: relative;
    margin: auto;
    width: 166px;
    height: 81px;
  }
  .Play {
    position: relative;
    margin: auto;
    width: 166px;
    height: 81px;
  }

  .Play button {
    cursor: pointer;
  }