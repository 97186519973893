.Hero {
    width: 200px;
    height: 250px;
    margin-right: 20px;
    padding: 0;
    background: none;
    border: none;
}

.HeroSelected {
    background: none;
    width: 200px;
    height: 250px;
    margin-right: 20px;
    padding: 0;
    border: 3px solid rgb(240, 173, 38);
}

.HeroImg {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.HeroName {
    position: relative;
    top:-50px;
    width: 100%;
    height: 20px;
    color: white;
    font-size: xx-large;
    font-family: "Comic Sans MS", "Comic Sans"
}

.HeroNameSelected {
    position: relative;
    top:-50px;
    width: 100%;
    height: 20px;
    color: rgb(240, 173, 38);
    font-size: xx-large;
    font-family: "Comic Sans MS", "Comic Sans"
}