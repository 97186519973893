  .GameTitle {
    position: relative;
    margin: auto;
    width: 600px;
    padding: 20px 5px;
  }

  .GameTitleButtons {
    display: flex;
    width: 508px;
    margin: auto;
    margin-top: 75px;
  }

  .GameTitleButtons .ImageButton {
      width: 206px;
      height: 96px;
      margin-left: 24px;
      margin-right: 24px;
      border: none;
      cursor: pointer;
  }

  .GameBottom {
    position: relative;
    top: 450px;
    margin: auto;
    width: 600px;
  }

  .GameBottom .VerticalAlignDiv {
      padding: 5px 10px;
  }