.ImageButton {
    width: 100%;
    height: auto;
    padding: 0;
    border: none;
    background: none;
    
  }

  .ImageTextButton {
    position: relative;
    top: -71px;
    height: 71px;
  }

  .ImageTextButton .VerticalAlignDiv {
    border: none;
  }

  .ImageButton:focus {
    outline: none;
    box-shadow: none;
  }